import React, { useState, useEffect } from 'react'
import style from './Header.module.css';
import logoPink from '../logo.svg';
import logoWhite from '../logoWhite.svg';
import { useLocation } from 'react-router-dom';


export default function Header() {
  let location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);
  useEffect(() => setCurrentLocation(location.pathname), [location])
  return (
    currentLocation.includes("register") ?
      <div className={`${style.headerWrapper}`}>
        <img src={logoPink} alt="site logo" />
      </div>
      : currentLocation.includes("menu") ?
        <div className={`${style.headerWrapperWhite}`}>
          <img src={logoWhite} alt="site logo" />
        </div>
        : ''
  )
}
