import React, { createContext, useState, useEffect } from "react";
export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  useEffect(() => {}, []);
  const [isCodeValid, setCodeValid] = useState(false);
  return (
    <AppContext.Provider value={{ isCodeValid, setCodeValid }}>
      {children}
    </AppContext.Provider>
  );
};