import React, { useState } from 'react'
import style from './Form.module.css'
import bg from '../../static/registerbg.jpg'
import Select from 'react-select'
import axios from "axios"
import { useCookies } from 'react-cookie'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


export default function RegisterForm() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [selectFromList, setSelectFromList] = useState('')

    const [cookies,] = useCookies(['qr_id']);
    const [, setCookie] = useCookies(['qr_auth_key']);

    let navigate = useNavigate();

    const registerUser = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Please check your passwords');
        } else {
            if (password.length < 6) {
                toast.error('The password must be at least 6 characters.');
            } else {
                if (selectFromList === '') {
                    toast.error("Please choose your group.")
                } else {
                    axios.post(`${process.env.REACT_APP_API}api/auth/register`, {
                        "name": firstName + ' ' + lastName,
                        email,
                        password,
                        "group": selectFromList,
                        "qr_id": cookies.qr_id,
                        "role_id": "2",
                    }).then((res) => {
                        if (res.data.access_token) {
                            setCookie('qr_auth_key', res.data.access_token, { path: '/', maxAge: res.data.expires_in });
                            navigate('/menu');
                        }
                    }).catch((e) => {
                        if (e.response.data.errors?.email) {
                            toast.error("Please choose another email address.")
                        } else if (e.response.data === "QR is taken") {
                            toast.error("QR is taken")
                        } else {
                            toast.error("Something went wrong, please try later!")
                        }
                    })
                }
            }


        }

    }
    const options = [
        { value: 'makers', label: 'Makers' },
        { value: 'startup-founders', label: 'Startup Founders' },
        { value: 'other', label: 'Other' }
    ]

    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#ffffff',
                fontSize: 16
            }
        }
    }

    const [showPw, setShowPw] = useState(false)
    const [showConfPw, setConfPw] = useState(false)

    return (
        <div style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }} className={style.registerWrapper}>
            <h1>Register</h1>
            <Toaster />
            <form onSubmit={registerUser}>
                <div className={style.formRow}>
                    <input required type={'text'} onChange={(e) => setFirstName(e.target.value)} placeholder={'First Name'} />
                    <input required type={'text'} onChange={(e) => setLastName(e.target.value)} placeholder={'Last Name'} />
                </div>
                <div className={style.formRow}>
                    <input required className={style.notThis} type={'email'} onChange={(e) => setEmail(e.target.value)} placeholder={'Email'} />
                </div>
                <div className={style.formRow}>
                    <p onClick={() => setShowPw(!showPw)}>{!showPw ? 'Show' : 'Hide'}</p>
                    <input required className={style.notThis} type={showPw ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} placeholder={'Password'} />
                </div>
                <div className={style.formRow}>
                    <p onClick={() => setConfPw(!showConfPw)}>{!showConfPw ? 'Show' : 'Hide'}</p>
                    <input required className={style.notThis} type={showConfPw ? 'text' : 'password'} onChange={(e) => setConfirmPassword(e.target.value)} placeholder={'Confirm Password'} />
                </div>
                <div className={style.formRow}>
                    <Select onChange={(select) => setSelectFromList(select.value)} isSearchable={false} placeholder={"Choose Group"} styles={customStyles} options={options} />
                </div>
                <button>Register</button>
            </form>
        </div>
    )
}
