import React, { useState, useEffect } from 'react'
import style from './Information.module.css';
import { useNavigate } from "react-router-dom";
import arrowBack from '../static/arrow-back.svg';
import pdfIcon from '../static/pdficon.svg'
import arrowRight from '../static/arrow-right.svg'
import logo from '../logoWhite.svg'
import extneralIcon from '../static/extenral.png'
import axios from "axios"
import { useCookies } from 'react-cookie';


export default function Information() {
    const navigate = useNavigate();
    const [info, setInfo] = useState([]);
    const [cookie,] = useCookies(['qr_auth_key']);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}api/client/pages/${process.env.REACT_APP_INFOPAGEID}`, {
            headers: { Authorization: `Bearer ${cookie.qr_auth_key}` }
        })
            .then(res => {
                if (res.data.page_content.length > 0) {
                    setInfo(JSON.parse(res.data.page_content[0].content))
                }
            }).catch((e) => {
                // setInfo(
                //     [{
                //         id: 1,
                //         type: "pdf",
                //         link: "#",
                //         title: "BEFORE YOU TRAVEL",
                //         bgColor: "#38B9CA"
                //     },
                //     {
                //         id: 2,
                //         type: "pdf",
                //         link: "#",
                //         title: "MAKING THE MOST OF YOUR STAY",
                //         bgColor: "#266887"
                //     },
                //     {
                //         id: 3,
                //         type: "pdf",
                //         link: "#",
                //         title: "ABOUT THE EVENT",
                //         bgColor: "#CA2B57"
                //     },
                //     {
                //         id: 4,
                //         type: "external",
                //         link: "#",
                //         title: "EXPLORE BELGRADE",
                //         bgColor: "#484848"
                //     }]
                // );
            })
    }, [cookie.qr_auth_key])

    return (
        <div>
            <div className={style.header}>
                <img onClick={() => navigate(-1)} src={arrowBack} alt="arrow back" />
                <h1>Information</h1>
            </div>
            <div className={style.wrapper}>
                <ul>
                    {info && info.map(item => {
                        return <li key={item.id} style={{
                            backgroundColor: item.bgColor
                        }}>
                            <a href={`${item.link}`} target="blank" className={style.pdfOne}><img className={style.linkIcon} src={item.type === 'pdf' ? pdfIcon : extneralIcon} alt="icon" />
                                {item.title}
                                <img className={style.arrowRight} src={arrowRight} alt="arrow right" /></a>
                        </li>
                    })}
                </ul>
                <img className={style.footerLogo} src={logo} alt="logo" />
            </div>
        </div>
    )
}
