
import { Navigate } from "react-router-dom";
import { useSearchParams, createSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export const AppProtected = ({ children }) => {
  const [searchParams] = useSearchParams();
  let isAppProtected = true;
  const [cookies, setCookie] = useCookies(['qr_id']);
  const [cookies2,] = useCookies(['qr_auth_key']);

  if (searchParams.get("qr_id")) {
    if (!cookies2.qr_auth_key) {
      setCookie('qr_id', searchParams.get("qr_id"), { path: '/', maxAge: 604800 });
    }
    return <Navigate to={{
      pathname: '/scavenger-hunt',
      search: createSearchParams({
        mode: "preview",
        back: false,
        qrId: searchParams.get("qr_id")
      }).toString()
    }} />;
  }
  if (isAppProtected && !cookies.qr_id) {
    return <Navigate to="/not-allowed" />;
  }
  if (cookies2.qr_auth_key && cookies.qr_id && !searchParams.get("preview")) {
    return <Navigate to="/menu" />;
  }
  /* eslint-disable eqeqeq */
  if (cookies2.qr_auth_key && cookies.qr_id && searchParams.get("preview") == true) {
    return <Navigate to="/qr-preview" />;
  }
  return children;
};