import React, { useEffect, useState } from 'react'
import style from './MyQR.module.css';
import { useNavigate } from "react-router-dom";
import arrowBack from '../static/arrow-back.svg';
import QR from '../static/qrtest.png';
import logo from '../logoWhite.svg'
import axios from 'axios'
import { useCookies } from 'react-cookie';


export default function MyQR() {

    const [cookie,] = useCookies(['qr_auth_key']);
    const [QRcode, setQRCode] = useState('');
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}api/client/info`, {
            headers: { Authorization: `Bearer ${cookie.qr_auth_key}` }
        }).then((res) => {
            if (res.data.info.user_info !== null) {
                setQRCode(res.data.info.user_info.qr.slug)
            }
        })
    }, [cookie.qr_auth_key, QRcode])
    const navigate = useNavigate();
    return (
        <div>
            <div className={style.header}>
                <img onClick={() => navigate(-1)} src={arrowBack} alt="arrow back" />
                <h1>My QR Code</h1>
            </div>
            <div className={style.wrapper}>
                <p>My personal QR Code</p>
                {QRcode && <img src={`${process.env.REACT_APP_API}${QRcode}`} className={style.QR} alt="Your QR" />}
                {!QRcode && <img src={QR} className={style.QRBlured} alt="user qr" />}
                <img className={style.footerLogo} src={logo} alt="logo" />
            </div>
        </div>
    )
}
