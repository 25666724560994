import React, { useState, useEffect } from 'react'
import style from './Menu.module.css'
import floorPlan from '../static/floorplan.svg';
import agenda from '../static/agenda.svg';
import info from '../static/information.svg';
import myQR from '../static/myqr.svg';
import hunt from '../static/hunt.svg';
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

export default function Menu() {
    const [menuItems] = useState([
        {
            name: "Floor Plan",
            link: "/floor-plan",
            backgroundColor: "#CA2B57",
            icon: floorPlan
        },
        {
            name: "Agenda",
            link: "/agenda",
            backgroundColor: "#484848",
            icon: agenda
        },
        {
            name: "Information",
            link: "/information",
            backgroundColor: "#F5AA75",
            icon: info
        },
        {
            name: "My QR Code",
            link: "/my-qr-code",
            backgroundColor: "#38B9CA",
            icon: myQR
        },
        {
            name: "Scavenger Hunt",
            link: "/scavenger-hunt",
            backgroundColor: "#266887",
            icon: hunt
        }
    ]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("qr")) {
            toast.error("QR is not valid")
        }
    }, [searchParams])
    return (
        <ul className={style.menuWrapper}>
            <Toaster />
            {menuItems.map((item, index) =>
                <Link to={item.link}>
                    <li key={item.name} style={{ backgroundColor: item.backgroundColor }}>
                        <img src={item.icon} alt={item.name} /><p>{item.name}</p>
                    </li></Link>
            )
            }
        </ul>
    )
}
