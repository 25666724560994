import { Navigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

export const AuthProtected = ({ children }) => {
    const isLogged = false;
    const [cookies] = useCookies(['qr_auth_key']);

    if (!isLogged && !cookies.qr_auth_key) {
        return <Navigate to="/register" />;
    }
    return children;
};