import React, { createContext, useState } from "react";


export const AdminContext = createContext();
export const infoRepeater = [
    // {
    //     id: 1,
    //     type: "pdf",
    //     link: "http://google.com",
    //     title: "BEFORE YOU TRAVEL",
    //     bgColor: "#38B9CA"
    // },
    // {
    //     id: 2,
    //     type: "pdf",
    //     link: "www.google.com",
    //     title: "MAKING THE MOST OF YOUR STAY",
    //     bgColor: "#266887"
    // },
    // {
    //     id: 3,
    //     type: "pdf",
    //     link: "www.google.com",
    //     title: "ABOUT THE EVENT",
    //     bgColor: "#CA2B57"
    // },
    // {
    //     id: 4,
    //     type: "external",
    //     link: "www.google.com",
    //     title: "EXPLORE BELGRADE",
    //     bgColor: "#484848"
    // }
];
export const AdminReducer = async (state, action) => {
    switch (action.type) {
        case "ADDCONTENT-NOPARSE":
            state = []
            action.payload.map((info) => {
                return state.push(info)
            })
            return state;
        case "ADDCONTENT":
            action.payload && JSON.parse(action.payload).map((info) => {
                return state.push(info)
            })
            return state;
        case 'UPDATEINFO':
            return state.map((info) => {
                if (info.id === action.payload.id) {
                    return Object.assign({}, info, {
                        ...action.payload
                    })
                }
                return info
            })
        case 'ADDNEWINFO':
            return [
                ...state,
                {
                    id: state.length + 1,
                    ...action.payload
                }
            ]
        default:
            return state
    }
}

export const agendaRepeater = [
    // {
    //     id: 1,
    //     date: "14 - Sep",
    //     day: {
    //         content: "Wednesday",
    //         badgde: "ih-marker"
    //     },
    //     timeLine: [
    //         {
    //             id: 1,
    //             timeAndColor: {
    //                 content: "10:00 am - 5:00 pm",
    //                 color: "#38B9CA"
    //             },
    //             activity: "EU Cluster Meeting (for EU Cluster Representatives only)",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 2,
    //             timeAndColor: {
    //                 content: "5:30 pm - 7:00 pm",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Registration, networking and welcome drinks at IH Belgrade",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 3,
    //             timeAndColor: {
    //                 content: "5:30 pm - 7:00 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Dinner at Kompot (hostel by IH Belgrade)",
    //             boldActivity: false,
    //         }
    //     ]
    // },
    // {
    //     id: 2,
    //     date: "15 - Sep",
    //     day: {
    //         content: "Thursday",
    //         badgde: "ih-marker"
    //     },
    //     timeLine: [
    //         {
    //             id: 1,
    //             timeAndColor: {
    //                 content: "8:30 am - 9:30 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Registration & coffee",
    //             boldActivity: true,
    //         },
    //         {
    //             id: 2,
    //             timeAndColor: {
    //                 content: "9:30 am - 10:00 am",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Opening ceremony",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 3,
    //             timeAndColor: {
    //                 content: "10:00 am - 1:00 pm",
    //                 color: "#CA2B57"
    //             },
    //             activity: "Maker innovation swap & hackathon",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 4,
    //             timeAndColor: {
    //                 content: "1:00 pm - 2:00 pm",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Lunch break & networking",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 5,
    //             timeAndColor: {
    //                 content: "2:00 pm - 2:30 pm",
    //                 color: "#CA2B57"
    //             },
    //             activity: "Building communities of Impact Makers",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 6,
    //             timeAndColor: {
    //                 content: "3:00 pm - 6:00 pm",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Discover Belgrade (along with IH Belgrade’s Members & Partners)",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 7,
    //             timeAndColor: {
    //                 content: "6:00 pm - 7:30 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Dinner (self-hosted)",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 8,
    //             timeAndColor: {
    //                 content: "6:00 pm - 7:30 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Theater play “I hate Theater play” performed by Reflektor Theatar (RSVP Only)",
    //             boldActivity: false,
    //         },
    //     ]
    // },
    // {
    //     id: 3,
    //     date: "16 - Sep",
    //     day: {
    //         content: "Friday",
    //         badgde: "ih-marker-and-startups"
    //     },
    //     timeLine: [
    //         {
    //             id: 1,
    //             timeAndColor: {
    //                 content: "9:00 am - 10:00 am",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Sharing reflections & looking to the day ahead",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 2,
    //             timeAndColor: {
    //                 content: "10:00 am - 1:00 pm",
    //                 color: "#CA2B57"
    //             },
    //             activity: "Maker-led workshops to connect, enable & inspire For Startup Founders: Matchmaking with Invesors",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 3,
    //             timeAndColor: {
    //                 content: "1:00 pm - 2:00 pm",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Lunch Break & Networking",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 4,
    //             timeAndColor: {
    //                 content: "2:00 pm - 3:00 pm",
    //                 color: "#CA2B57"
    //             },
    //             activity: "Inclusive entrepreneurship with EuroPride",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 5,
    //             timeAndColor: {
    //                 content: "4:00 pm - 4:30 pm",
    //                 color: "#CA2B57"
    //             },
    //             activity: "Closing Ceremony",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 6,
    //             timeAndColor: {
    //                 content: "4:30 pm - 5:00 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Coffee break",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 7,
    //             timeAndColor: {
    //                 content: "5:00 pm - 6:00 pm",
    //                 color: "#CA2B57"
    //             },
    //             activity: "Startup Pitches for a GreenTransition",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 8,
    //             timeAndColor: {
    //                 content: "6:00 pm - 8:30 pm",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Closing celebrations",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 9,
    //             timeAndColor: {
    //                 content: "8:30 pm - 10:00 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Choose your own adventure (self - hosted)",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 10,
    //             timeAndColor: {
    //                 content: "10:00 pm - 12:00 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Queen Real Tribute Band LIVE (RSVP Only)",
    //             boldActivity: false,
    //         },
    //     ]
    // },
    // {
    //     id: 4,
    //     date: "17 - Sep",
    //     day: {
    //         content: "Saturday",
    //         badgde: "optional"
    //     },
    //     timeLine: [
    //         {
    //             id: 1,
    //             timeAndColor: {
    //                 content: "10:30 am - 11:30 am",
    //                 color: "#CA2B57"
    //             },
    //             activity: "EuroPride workshops and events",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 2,
    //             timeAndColor: {
    //                 content: "12:00 pm - 2:00 pm",
    //                 color: "#38B9CA"
    //             },
    //             activity: "Brunch with IH Belgrade Foundersand Makers",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 3,
    //             timeAndColor: {
    //                 content: "2:30 pm - 7:00 pm",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Pride Parade",
    //             boldActivity: false,
    //         },
    //         {
    //             id: 4,
    //             timeAndColor: {
    //                 content: "7:30 pm - 12:00 am",
    //                 color: "#F5AA75"
    //             },
    //             activity: "Closing party of the Belgrade EuroPride Week",
    //             boldActivity: false,
    //         }
    //     ]
    // }
];
export const AdminAgendaReducer = (state, action) => {
    switch (action.type) {
        case "ADDCONTENT":
            action.payload && JSON.parse(action.payload).map((info) => {
                return state.push(info)
            })
            return state;
        case 'UPDATEAGENDA':
            return state.map((agenda) => {
                if (agenda.id === action.payload.id) {
                    return Object.assign({}, agenda, {
                        ...action.payload
                    })
                }
                return agenda
            })
        case 'ADDNEWAGENDA':
            return [
                ...state,
                {
                    id: state.length + 1,
                    ...action.payload
                }
            ]
        default: return state;
    }
}


export const AdminProvider = ({ children }) => {
    const [isLogged, setIsLogged] = useState(false)
    return (
        <AdminContext.Provider value={{ isLogged, setIsLogged, infoRepeater, agendaRepeater }}>
            {children}
        </AdminContext.Provider>
    );
}