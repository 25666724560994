import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => document.getElementById('scroller')?.scrollIntoView({ behavior: 'smooth' }), 100)
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;