import React, { createContext, useState, useEffect } from "react";
export const UserContext = createContext();
export const UserProvider = ({ children }) => {
  useEffect(() => { }, []);
  const [isCodeValid, setCodeValid] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  return (
    <UserContext.Provider value={{ isCodeValid, setCodeValid, accessToken, setAccessToken }}>
      {children}
    </UserContext.Provider>
  );
};