import React, { useState, useContext, useEffect } from 'react'
import style from './Form.module.css'
import logo from '../../logo.svg'
import { AdminContext } from '../../context/AdminContext'
import axios from 'axios'
import { useCookies } from 'react-cookie';

export default function Form() {
    console.log(process.env.REACT_APP_API)
    const { setIsLogged } = useContext(AdminContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [adminCookie, setAdminCookie] = useCookies(['admin_cooke_inh']);
    const loginAdmin = (e) => {
        e.preventDefault();
        if (email !== '' && password !== '') {
            axios.post(`${process.env.REACT_APP_API}api/auth/login`, {
                email,
                password
            }).then((res) => {
                if (res.status === 200) {
                    setEmail('');
                    setPassword('');
                    setIsLogged(true);
                    setAdminCookie('admin_cooke_inh', res.data.access_token, { path: '/', maxAge: res.data.expires_in });
                }
            })
        }
    }

    useEffect(() => {
        if (adminCookie.admin_cooke_inh) {
            setIsLogged(true);
        }
    }, [adminCookie, setIsLogged])

    return (
        <div className={style.wrapper}>
            <img src={logo} alt="Logo" />
            <form onSubmit={loginAdmin} className={style.form}>
                <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                <button>Login</button>
            </form>
        </div>
    )
}
