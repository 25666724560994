import './App.css';
import { useEffect, useContext } from 'react';
import { AppContext } from './context/AppContext';

function App() {
  const appContext = useContext(AppContext);
  useEffect(() => {
    // console.log(appContext);
  }, [appContext]);
  return (
    <div className="App">
    </div>
  );
}

export default App;
