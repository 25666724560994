import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Register from './screens/Register';
import Notallowed from './screens/Notallowed';
import { AppProvider } from './context/AppContext';
import { UserProvider } from './context/UserContext';
import { AppProtected } from './middlewares/AppProtect';
import { AuthProtected } from './middlewares/AuthProtect';
import { AdminProvider } from './context/AdminContext';
import Header from './components/Header';
import Menu from './screens/Menu';
import Floorplan from './screens/Floorplan';
import Agenda from './screens/Agenda';
import Information from './screens/Information';
import MyQR from './screens/MyQR';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';
import ScrollToTop from './components/ScrollToTop';
import Profile from './screens/Profile';
import AdminArea from './screens/Admin';
import RegisterForm from './components/register/Form';
import Login from './screens/Login'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <GoogleOAuthProvider clientId="718252684478-ih7k282lhosvvu5qedtt5vnbd1uv6ij9.apps.googleusercontent.com">
      <BrowserRouter>
        <ScrollToTop>
          <AppProvider>
            <UserProvider>
              <Header />
              <Routes>
                <Route path="/" element={
                  <AppProtected>
                    <AuthProtected>
                      <App />
                    </AuthProtected>
                  </AppProtected>} />
                <Route path="/register" element={<AppProtected><Register /></AppProtected>} />
                <Route path="/login" element={<AppProtected><Login /></AppProtected>} />
                <Route path="/not-allowed" element={<Notallowed />} />
                <Route path="/menu" element={<AuthProtected><Menu /></AuthProtected>} />
                <Route path="/floor-plan" element={<AuthProtected><Floorplan /></AuthProtected>} />
                <Route path="/agenda" element={<AuthProtected><Agenda /></AuthProtected>} />
                <Route path="/information" element={<AuthProtected><Information /></AuthProtected>} />
                <Route path="/my-qr-code" element={<AuthProtected><MyQR /></AuthProtected>} />
                <Route path="/scavenger-hunt" element={<AuthProtected><Profile /></AuthProtected>} />
                <Route path="/admin" element={<AdminProvider><AdminArea /></AdminProvider>} />
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route path="/register-form" element={<AppProtected><RegisterForm /></AppProtected>} />
              </Routes>
            </UserProvider>
          </AppProvider>
        </ScrollToTop>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
