import React, { useState, useEffect } from 'react'
import { ListGroup, Accordion, Button, Modal, Form, Spinner } from 'react-bootstrap';
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import { useCookies } from 'react-cookie';



export default function InfoTab({ info, dispatch }) {
    const [show, setShow] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [newRow, setNewRow] = useState(false)
    const [newItem, setNewItem] = useState({})
    const [showSpinner, setShowSpinner] = useState(false)
    const [adminCookie,] = useCookies(['admin_cooke_inh']);
    const [runUpdate, setRunUpdate] = useState(false)
    const [runRowUpdate, setRunRowUpdate] = useState(false)


    useEffect(() => {
        addNewRow('type', 'pdf')
        if (runUpdate) {
            info.then(newData => {
                updateInfoPage(newData).then(async (res) => {
                    if (res.status === 200) {
                        await dispatch({ type: "ADDCONTENT-NOPARSE", payload: newData })
                        setShow(false)
                        toast.success('Content update successfully!')
                        setShowSpinner(false)
                        setRunUpdate(false)
                        window.location.reload();
                    }
                })
            })
        }
        if (runRowUpdate) {
            info.then(newData => {
                updateInfoPage(newData).then((res) => {
                    if (res.status === 200) {
                        setShow(false)
                        toast.success('Content update successfully!')
                        setShowSpinner(false)
                        setNewRow(false)
                        setNewItem({})
                        window.location.reload();
                    }
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info, runUpdate, runRowUpdate])

    const updateItem = (id, name, value) => {
        setEditItem({
            ...editItem,
            [name]: value
        })
    }

    const pickItem = (id) => {
        let choosenItem = info.find(x => x.id === id);
        setEditItem(choosenItem);
    }

    const deleteItem = (id) => {
        let index = info.findIndex(function (o) {
            return o.id === id;
        })
        if (index !== -1) info.splice(index, 1);
        updateInfoPage(info).then(async (res) => {
            if (res.status === 200) {
                setShow(false)
                toast.success('Content update successfully!')
                setShowSpinner(false)
                setRunUpdate(false)
                window.location.reload();
            }
        });
    }

    const addNewRow = (name, value) => {
        setNewItem({
            ...newItem,
            [name]: value
        })
    }

    const updateInfoPage = async (content) => {
        return await axios.put(`${process.env.REACT_APP_API}api/pages/${process.env.REACT_APP_INFOPAGEID}`, {
            body_content: JSON.stringify(content),
            title: "Informations",
            status_id: 2,
        }, {
            headers: { Authorization: `Bearer ${adminCookie.admin_cooke_inh}` }
        })
    }

    return (
        <div>
            {showSpinner && <div style={{
                backgroundColor: "white",
                opacity: 0.5,
                width: "100%",
                height: "100vh",
                zIndex: "123",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                position: "fixed",
                left: 0,
                top: 0,
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner></div>}
            <Toaster
                position="bottom-center"
                reverseOrder={false}
            />
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit item: {editItem.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Form.Select onChange={(e) => updateItem(editItem.id, 'type', e.target.value)} defaultValue={editItem.type} aria-label="Default select">
                                <option defaul selected value="pdf">PDF</option>
                                <option value="external">External</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                type="text"
                                id="link"
                                value={editItem.link}
                                onChange={(e) => updateItem(editItem.id, 'link', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                id="title"
                                value={editItem.title}
                                onChange={(e) => updateItem(editItem.id, 'title', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Color</Form.Label>
                            <Form.Control
                                type="color"
                                defaultValue={editItem.bgColor}
                                title="Choose your color"
                                onChange={(e) => updateItem(editItem.id, 'bgColor', e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        await dispatch({ type: "UPDATEINFO", payload: editItem });
                        setRunUpdate(true)
                        setShowSpinner(true)
                        setShow(false)

                    }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='p-2 pl-0 '>
                <ListGroup>
                    {info.length > 0 && info.map(i => {
                        return <ListGroup.Item key={i.id}>
                            <Accordion>
                                <Accordion.Item eventKey={i.id}>
                                    <Accordion.Header>{i.title}</Accordion.Header>
                                    <Accordion.Body>
                                        <ListGroup>
                                            <ListGroup.Item>Type: {i.type}</ListGroup.Item>
                                            <ListGroup.Item>Link: {i.link}</ListGroup.Item>
                                            <ListGroup.Item>Title: {i.title}</ListGroup.Item>
                                            <ListGroup.Item>Color: <span style={{
                                                backgroundColor: i.bgColor,
                                                color: "white"
                                            }} className='p-2'>{i.title}</span></ListGroup.Item>
                                        </ListGroup>
                                        <Button onClick={() => { pickItem(i.id); setShow(true) }} className='mt-2' variant="warning">Edit this item</Button>
                                        <Button variant="danger" onClick={() => deleteItem(i.id)} className='mt-2 mx-2'>Delete</Button>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </ListGroup.Item>
                    })}
                </ListGroup>
                {newRow && <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Type</Form.Label>
                        <Form.Select onChange={(e) => addNewRow('type', e.target.value)} defaultValue={'pdf'} aria-label="Default select">
                            <option value="pdf">PDF</option>
                            <option value="external">External</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            id="link"
                            placeholder='Please enter link here'
                            onChange={(e) => addNewRow('link', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            id="title"
                            placeholder='Please enter title here'
                            onChange={(e) => addNewRow('title', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Color</Form.Label>
                        <Form.Control
                            type="color"
                            title="Choose your color"
                            onChange={(e) => addNewRow('bgColor', e.target.value)}
                        />
                    </Form.Group>
                    <Button disabled={!newRow} onClick={async () => {
                        if (Object.keys(newItem).length < 4) {
                            alert("Please fill all inputs!")
                        } else {
                            await dispatch({ type: "ADDNEWINFO", payload: newItem })
                            setShowSpinner(true)
                            setShow(false)
                            setRunRowUpdate(true)
                        }
                    }} className='mt-2' variant="warning">Save it</Button>
                </Form>}
                <Button disabled={newRow} onClick={() => { setNewRow(true) }} className='mt-2' variant="warning">Add new row</Button>
            </div>
        </div>
    )
}
