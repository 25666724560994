import React, { useState } from 'react'
import style from './Floorplan.module.css';
import arrowBack from '../static/arrow-back.svg';
import { useNavigate } from "react-router-dom";
import stairs from '../static/stairs.svg';
import groundFloor from '../static/groundfloor.png'
import secoundFloor from '../static/secoundfloor.png'

export default function Floorplan() {
    const navigate = useNavigate();
    const [groundFloorItems] = useState([
        {
            num: 1,
            text: "Entrance"
        },
        {
            num: 2,
            text: "Coat Room"
        },
        {
            num: 3,
            text: "Meeting Room 1"
        },
        {
            num: 4,
            text: "Kitchen"
        },
        {
            num: 5,
            text: "Toilets"
        },
        {
            num: 6,
            text: "Main Area"
        },
        {
            num: 7,
            text: "Stairway"
        },

    ]);
    const [secoundFloorItems] = useState([
        {
            num: 1,
            text: "Stairway"
        },
        {
            num: 2,
            text: "Common Area"
        },
        {
            num: 3,
            text: "Desk Space"
        },
        {
            num: 4,
            text: "Kitchen"
        },
        {
            num: 5,
            text: "Gallery"
        },
        {
            num: 6,
            text: "Terrace/Break Area"
        },
    ]);
    return (
        <div>
            <div className={style.header}>
                <img onClick={() => navigate(-1)} src={arrowBack} alt="arrow back" />
                <h1>Floor plan</h1>
            </div>
            <div className={style.wrapper}>
                <div className={style.floorItem}>
                    <span><img src={stairs} alt="stairs" />Ground Floor</span>
                    <img src={groundFloor} alt="ground floor" />
                    <div className={style.floorDescription}>
                        <ul>
                            {groundFloorItems.map(floor =>
                                <li key={floor.num}><span className={style.floorSpanNum}>{floor.num}</span><p>{floor.text}</p></li>)}
                        </ul>
                    </div>
                </div>
                <div className={style.floorItem}>
                    <span><img src={stairs} alt="stairs" />Second Floor</span>
                    <img src={secoundFloor} alt="ground floor" />
                    <div className={style.floorDescription}>
                        <ul>
                            {secoundFloorItems.map(floor =>
                                <li key={floor.num}><span className={style.floorSpanNum}>{floor.num}</span><p>{floor.text}</p></li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
