import React, { useState, useEffect } from 'react'
import style from './Profile.module.css';
import { useNavigate } from "react-router-dom";
import arrowBack from '../static/arrow-back.svg';
import avatar from '../static/profile.svg';
import { useSearchParams } from 'react-router-dom';
import logo from '../logoWhite.svg'
import axios from 'axios'
import { useCookies } from 'react-cookie';


export default function Profile() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [info, setInfo] = useState('')
    const [totalScanned, setTotalScanned] = useState(0);
    const [total,] = useState(200);
    const [totalScannedPercentage, setTotalScannedPercentage] = useState(0);
    const [cookie,] = useCookies(['qr_auth_key']);
    const [cookies, setCookies] = useCookies(['qr_id']);

    useEffect(() => {
        if (!searchParams.get("qrId")) {
            axios.get(`${process.env.REACT_APP_API}api/client/info`, {
                headers: { Authorization: `Bearer ${cookie.qr_auth_key}` }
            }).then((res) => {
                if (res.data.info.user_info) {
                    setCookies('qr_id', res.data.info.user_info.qr.id, { path: '/', maxAge: 3600000 })
                }
                if (res.data.scans > 0) {
                    setTotalScannedPercentage(res.data.scans / total * 100);
                    setTotalScanned(res.data.scans)
                }
            })
        }
        if (searchParams.get("qrId") || searchParams.get("mode")) {
            axios.post(`${process.env.REACT_APP_API}api/client/scan-qr`, {
                "qr_id": searchParams.get("qrId") || cookies.qr_id
            }, {
                headers: { Authorization: `Bearer ${cookie.qr_auth_key}` }
            }).then((res) => {
                if (res.data) {
                    setInfo(res.data)
                }
            }).catch((e) => {
                navigate('/menu?qr=false');
            })
        }
    }, [searchParams, total]);

    return (
        <div>
            <div className={style.header}>
                <img onClick={() => searchParams.get("back") ? navigate('/') : navigate(-1)} src={arrowBack} alt="arrow back" />
                <h1>Scavenger Hunt</h1>
            </div>
            <div className={style.wrapper}>
                {searchParams.get("mode") && <img src={avatar} alt="avatar" />}
                {searchParams.get("mode") && info && <div className={style.nameWrapper}>
                    <span>Name</span>
                    <div className={style.nameBox}>{info.name}</div>
                </div>}
                {searchParams.get("mode") && info && <div className={`${style.nameWrapper} ${style.myFact}`}>
                    <span>My Fact</span>
                    <div className={style.nameBox}>
                        {info.user_info.text}
                    </div>
                </div>}
                {!searchParams.get("mode") && <h2>What is a QR Code
                    scavenger hunt?</h2>}
                {!searchParams.get("mode") && <p>A scavenger hunt using QR Codes is essentially a digital version of a traditional scavenger hunt, wherein you define a list of particular objects that teams have to collect, usually without purchasing them. Creating a scavenger hunt with QR Codes can offer numerous ways to make the game much more exciting, fun, and engaging for the participants.</p>}
                {!searchParams.get("mode") && <div className={style.howMuchScan}>
                    <div className={style.progress} style={{
                        width: totalScannedPercentage + "%",
                        transition: "all 0.3s"
                    }}>
                    </div></div>}
                {!searchParams.get("mode") && <p className={style.strongP}>{totalScanned}/{total}</p>}
                {!searchParams.get("mode") && <div className={style.authSection}>
                    <button onClick={() => navigate('/scavenger-hunt?mode=preview')}>My Fun Fact</button>
                </div>}
                <img className={style.footerLogo} src={logo} alt="logo" />
            </div>
        </div>
    )
}
