import React, { useState, useEffect } from 'react'
import style from './Agenda.module.css';
import { useNavigate } from "react-router-dom";
import arrowBack from '../static/arrow-back.svg';
import ihMarker from '../static/ih-marker.svg'
import ihMarkerAndStartup from '../static/ih-marker-and-startup.svg'
import optional from '../static/optional.svg'
import { useCookies } from 'react-cookie';
import axios from 'axios'

export default function Agenda() {
    const [cookie,] = useCookies(['qr_auth_key']);
    const [agenda, setAgenda] = useState([
        // {
        //     id: 1,
        //     date: "14 - Sep",
        //     day: {
        //         content: "Wednesday",
        //         badgde: "ih-marker"
        //     },
        //     timeLine: [
        //         {
        //             id: 1,
        //             timeAndColor: {
        //                 content: "10:00 am - 5:00 pm",
        //                 color: "#38B9CA"
        //             },
        //             activity: "EU Cluster Meeting (for EU Cluster Representatives only)",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 2,
        //             timeAndColor: {
        //                 content: "5:30 pm - 7:00 pm",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Registration, networking and welcome drinks at IH Belgrade",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 3,
        //             timeAndColor: {
        //                 content: "5:30 pm - 7:00 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Dinner at Kompot (hostel by IH Belgrade)",
        //             boldActivity: false,
        //         }
        //     ]
        // },
        // {
        //     id: 2,
        //     date: "15 - Sep",
        //     day: {
        //         content: "Thursday",
        //         badgde: "ih-marker"
        //     },
        //     timeLine: [
        //         {
        //             id: 1,
        //             timeAndColor: {
        //                 content: "8:30 am - 9:30 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Registration & coffee",
        //             boldActivity: true,
        //         },
        //         {
        //             id: 2,
        //             timeAndColor: {
        //                 content: "9:30 am - 10:00 am",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Opening ceremony",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 3,
        //             timeAndColor: {
        //                 content: "10:00 am - 1:00 pm",
        //                 color: "#CA2B57"
        //             },
        //             activity: "Maker innovation swap & hackathon",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 4,
        //             timeAndColor: {
        //                 content: "1:00 pm - 2:00 pm",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Lunch break & networking",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 5,
        //             timeAndColor: {
        //                 content: "2:00 pm - 2:30 pm",
        //                 color: "#CA2B57"
        //             },
        //             activity: "Building communities of Impact Makers",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 6,
        //             timeAndColor: {
        //                 content: "3:00 pm - 6:00 pm",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Discover Belgrade (along with IH Belgrade’s Members & Partners)",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 7,
        //             timeAndColor: {
        //                 content: "6:00 pm - 7:30 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Dinner (self-hosted)",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 8,
        //             timeAndColor: {
        //                 content: "6:00 pm - 7:30 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Theater play “I hate Theater play” performed by Reflektor Theatar (RSVP Only)",
        //             boldActivity: false,
        //         },
        //     ]
        // },
        // {
        //     id: 3,
        //     date: "16 - Sep",
        //     day: {
        //         content: "Friday",
        //         badgde: "ih-marker-and-startups"
        //     },
        //     timeLine: [
        //         {
        //             id: 1,
        //             timeAndColor: {
        //                 content: "9:00 am - 10:00 am",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Sharing reflections & looking to the day ahead",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 2,
        //             timeAndColor: {
        //                 content: "10:00 am - 1:00 pm",
        //                 color: "#CA2B57"
        //             },
        //             activity: "Maker-led workshops to connect, enable & inspire For Startup Founders: Matchmaking with Invesors",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 3,
        //             timeAndColor: {
        //                 content: "1:00 pm - 2:00 pm",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Lunch Break & Networking",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 4,
        //             timeAndColor: {
        //                 content: "2:00 pm - 3:00 pm",
        //                 color: "#CA2B57"
        //             },
        //             activity: "Inclusive entrepreneurship with EuroPride",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 5,
        //             timeAndColor: {
        //                 content: "4:00 pm - 4:30 pm",
        //                 color: "#CA2B57"
        //             },
        //             activity: "Closing Ceremony",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 6,
        //             timeAndColor: {
        //                 content: "4:30 pm - 5:00 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Coffee break",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 7,
        //             timeAndColor: {
        //                 content: "5:00 pm - 6:00 pm",
        //                 color: "#CA2B57"
        //             },
        //             activity: "Startup Pitches for a GreenTransition",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 8,
        //             timeAndColor: {
        //                 content: "6:00 pm - 8:30 pm",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Closing celebrations",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 9,
        //             timeAndColor: {
        //                 content: "8:30 pm - 10:00 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Choose your own adventure (self - hosted)",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 10,
        //             timeAndColor: {
        //                 content: "10:00 pm - 12:00 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Queen Real Tribute Band LIVE (RSVP Only)",
        //             boldActivity: false,
        //         },
        //     ]
        // },
        // {
        //     id: 4,
        //     date: "17 - Sep",
        //     day: {
        //         content: "Saturday",
        //         badgde: "optional"
        //     },
        //     timeLine: [
        //         {
        //             id: 1,
        //             timeAndColor: {
        //                 content: "10:30 am - 11:30 am",
        //                 color: "#CA2B57"
        //             },
        //             activity: "EuroPride workshops and events",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 2,
        //             timeAndColor: {
        //                 content: "12:00 pm - 2:00 pm",
        //                 color: "#38B9CA"
        //             },
        //             activity: "Brunch with IH Belgrade Foundersand Makers",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 3,
        //             timeAndColor: {
        //                 content: "2:30 pm - 7:00 pm",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Pride Parade",
        //             boldActivity: false,
        //         },
        //         {
        //             id: 4,
        //             timeAndColor: {
        //                 content: "7:30 pm - 12:00 am",
        //                 color: "#F5AA75"
        //             },
        //             activity: "Closing party of the Belgrade EuroPride Week",
        //             boldActivity: false,
        //         }
        //     ]
        // },
    ]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}api/client/pages/${process.env.REACT_APP_AGENDAPGEID}`, {
            headers: { Authorization: `Bearer ${cookie.qr_auth_key}` }
        })
            .then(res => {
                if (res.data.page_content.length > 0) {
                    setAgenda(JSON.parse(res.data.page_content[0].content))
                }
            })
    }, [cookie.qr_auth_key]);
    const navigate = useNavigate();
    return (
        <div>
            <div className={style.header}>
                <img onClick={() => navigate(-1)} src={arrowBack} alt="arrow back" />
                <h1>Agenda</h1>
            </div>
            <div className={style.agendaWrapper}>
                <h3>About the gathering</h3>
                <h2>BELGRADE ’22</h2>
                <ul>
                    {agenda.map((item, index) => {
                        return <li key={item.id}>
                            <div className={style.dayWrapperUp}>
                                <span>Day {index + 1} | </span><p>{item.date}</p>
                            </div>
                            <div className={style.dayWrapperDown}>
                                <h4>{item.day.content}
                                    <span>
                                        <img src={item.day.badgde === 'ih-marker' ? ihMarker : item.day.badgde === 'ih-marker-and-startups' ? ihMarkerAndStartup : optional} width="13" height="13" alt={item.day.badgde} />
                                    </span>
                                </h4>
                            </div>
                            <ul className={style.dayWrapperList}>
                                {item.timeLine.map((timeLineItem, index) => {
                                    return <li key={timeLineItem.id}>
                                        <div style={{
                                            color: timeLineItem.timeAndColor.color
                                        }} className={style.timeAndColor}>
                                            {timeLineItem.timeAndColor.content}
                                        </div>
                                        <div style={{
                                            fontWeight: timeLineItem.boldActivity ? 'bold' : 'normal'
                                        }} className={style.activity}>
                                            {timeLineItem.activity}
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </li>
                    })}
                </ul>
                <div className={style.agendaBadgets}>
                    <ul>
                        <li><img src={ihMarker} width="13" height="13" alt="ih marker" /><p>For IH Makers only</p></li>
                        <li><img src={ihMarkerAndStartup} width="13" height="13" alt="ih marker and startups" /><p>For IH Makers and Startup Founders</p></li>
                        <li><img src={optional} width="13" height="13" alt="optional" /><p>Optional for all</p></li>
                    </ul>
                    <ul>
                        <li><div className={style.orangeBox}></div><p>Fun/ Leisure time</p></li>
                        <li><div className={style.redBox}></div><p>Hands-on/ work sessions</p></li>
                        <li><div className={style.blueBox}></div><p>Group sessions</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
