import React, { useState } from 'react'
import style from './Login.module.css'
import river from '../river1.png';
import viaGoogle from '../viagoogle.svg';
import { useGoogleLogin } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';



export default function Login() {
  const [, setCookie] = useCookies(['qr_auth_key']);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [showPw, setShowPw] = useState(false)
  let navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      if (credentialResponse?.access_token) {
        const token = credentialResponse.access_token;
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse?.access_token}`).then(res => {
          if (res.status === 200) {
            const { email } = res.data;
            axios.post(process.env.REACT_APP_API + 'api/auth/google-login', {
              email,
              token
            }).then((res) => {
              if (!res.data.access_token) {
                toast.error("Login credentials are not valid!")
              } else {
                setCookie('qr_auth_key', res.data.access_token, { path: '/', maxAge: res.data.expires_in });
                navigate('/menu');
              }
            })
          }
        })
      }
    },
  });


  const loginUser = () => {
    if (!email || !password) {
      toast.error("Please enter email or password first")
    } else {
      axios.post(process.env.REACT_APP_API + 'api/auth/login', {
        email,
        password
      }).then((res) => {
        if (!res.data.access_token) {
          toast.error("Login credentials are not valid!")
        } else {
          setCookie('qr_auth_key', res.data.access_token, { path: '/', maxAge: res.data.expires_in });
          navigate('/menu');
        }
      }).catch((e) => toast.error("Login credentials are not valid!"))
    }
  }
  return (
    <div>
      <Toaster />
      <div className={style.heroSection}>
        <img src={river} alt="river" />
        <h1>Log into your account</h1>
        <form onSubmit={(e) => e.preventDefault()} className={style.registerWrapper}>
          <div className={style.formRow}>
            <input required className={style.notThis} type={'email'} onChange={(e) => setEmail(e.target.value)} placeholder={'Email'} />
          </div>
          <div className={style.formRow}>
            <p onClick={() => setShowPw(!showPw)}>{!showPw ? 'Show' : 'Hide'}</p>
            <input required className={style.notThis} type={showPw ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} placeholder={'Password'} />
          </div>
        </form>
      </div>
      <div className={style.authSection}>
        <Link to={'#'}><button onClick={loginUser}>Login</button></Link>
        <p>or</p>
        <button onClick={login}>
          <img src={viaGoogle} alt="via google" />
        </button>
        <p className={style.paddingTop}>If you don't have account, please<Link to={'/register-form'}> register</Link></p>
      </div>
    </div>
  )
}
