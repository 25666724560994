import React from 'react'
import style from './Notallowed.module.css'

export default function Notallowed() {
  return (
    <div className={style.wrapper}>
      <h1>Oops!</h1>
      <h2>You cannot access the application. Scan the QR code first.</h2>
    </div>
  )
}
