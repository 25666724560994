import React, { useReducer } from 'react'
import { infoRepeater, AdminReducer } from '../../context/AdminContext'
import { agendaRepeater, AdminAgendaReducer } from '../../context/AdminContext'
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InfoTab from './components/Info'
import AgendaTab from './components/Agenda'


export default function Home() {
    const [info, dispatch] = useReducer(AdminReducer, infoRepeater);
    const [agenda,] = useReducer(AdminAgendaReducer, agendaRepeater);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 p-2'>
                    <Alert variant={'info'}>
                        <b>Welcome admin</b>, please change the data carefully.
                    </Alert>
                </div>
                <div className='col-12'>
                    <Tabs
                        defaultActiveKey="home"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="Informations">
                            <InfoTab info={info} dispatch={dispatch} />
                        </Tab>
                        <Tab eventKey="profile" title="Agenda">
                            <AgendaTab agenda={agenda} dispatch={dispatch} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}
