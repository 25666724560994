import React, { useContext, useEffect, useState, useReducer } from 'react'
import Form from '../components/admin/Form'
import { AdminContext } from '../context/AdminContext'
import Home from '../components/admin/Home'
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { AdminReducer, infoRepeater, agendaRepeater, AdminAgendaReducer } from '../context/AdminContext'



export default function AdminArea() {
    const [adminCookie,] = useCookies(['admin_cooke_inh']);
    const [, dispatch] = useReducer(AdminReducer, infoRepeater);
    const [, dispatch2] = useReducer(AdminAgendaReducer, agendaRepeater);
    const [spinner, setSpinner] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        setSpinner(true)
        axios.get(`${process.env.REACT_APP_API}api/pages/${process.env.REACT_APP_INFOPAGEID}`, {
            headers: { Authorization: `Bearer ${adminCookie.admin_cooke_inh}` }
        }).then((res) => {
            if (res.status === 200) {
                dispatch({ type: "ADDCONTENT", payload: res.data.page_content[0].content })
                axios.get(`${process.env.REACT_APP_API}api/pages/${process.env.REACT_APP_AGENDAPGEID}`, {
                    headers: { Authorization: `Bearer ${adminCookie.admin_cooke_inh}` }
                }).then((res) => {
                    if (res.status === 200) {
                        dispatch2({ type: "ADDCONTENT", payload: res.data.page_content[0].content })
                        setSpinner(false)
                        setDataLoaded(true)
                    }
                })
            }
        })
    }, [adminCookie.admin_cooke_inh, dispatch])
    const { isLogged } = useContext(AdminContext);
    return (
        <>
            {isLogged && spinner && <div style={{
                backgroundColor: "white",
                opacity: 0.5,
                width: "100%",
                height: "100vh",
                zIndex: "123",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                position: "fixed",
                left: 0,
                top: 0,
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner></div>}
            <div>{!isLogged && <Form />}</div>
            <div>{isLogged && dataLoaded && <Home />}</div>
        </>

    )
}
