import React from 'react'
import style from './Register.module.css'
import river from '../river1.png';
import viaGoogle from '../viagoogle.svg';
import { useGoogleLogin } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';



export default function Register() {
  const [, setCookie] = useCookies(['qr_auth_key']);
  const [cookies,] = useCookies(['qr_id']);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      if (credentialResponse?.access_token) {
        // const token = credentialResponse.access_token;
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse?.access_token}`).then(res => {
          if (res.status === 200) {
            const { name, email } = res.data;
            axios.post(process.env.REACT_APP_API + 'api/auth/register', {
              name,
              email,
              token: credentialResponse.access_token,
              "qr_id": searchParams.get("qr_id") || cookies.qr_id,
              "role_id": "2",
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            }).then((res) => {
              if (res.data.access_token) {
                setCookie('qr_auth_key', res.data.access_token, { path: '/', maxAge: res.data.expires_in });
                navigate('/menu');
              }
            }).catch((e) => {
              if (e.response.data.errors?.email) {
                toast.error("Please choose another email address.")
              } else if (e.response.data === "QR is taken") {
                toast.error("QR is taken")
              } else {
                toast.error("Something went wrong, please try later!")
              }
            })
          }
        })
      }
    },
  });
  return (
    <div>
      <Toaster />
      <div className={style.heroSection}>
        <img src={river} alt="river" />
        <h1>Impact Hub European Gathering ‘22</h1>
      </div>
      <div className={style.authSection}>
        <Link to={'/register-form'}><button>Register</button></Link>
        <p>or</p>
        <button onClick={login}>
          <img src={viaGoogle} alt="via google" />
        </button>
        <p className={style.paddingTop}>If you have account, please<Link to={'/login'}> login</Link></p>
      </div>
    </div>
  )
}
